export const requestTypes = {
  ORDER: { type: "ORDER", displayName: "Order", icon: "silverware" },
  CHECK: { type: "CHECK", displayName: "Check", icon: "cash" },
  REFILL: { type: "REFILL", displayName: "Refill", icon: "cup" },
  CLEAN: { type: "CLEAN", displayName: "Clean", icon: "broom" },
  LONG_WAIT: {
    type: "LONG_WAIT",
    displayName: "Long Wait",
    icon: "clock-outline",
  },
  ROOM_SERVICE: {
    type: "ROOM_SERVICE",
    displayName: "Room Service",
    icon: "room-service",
  },
  HELP: { type: "HELP", displayName: "Help", icon: "help" },
};

export const requestTypesLists = {
  RESTAURANT: [
    requestTypes.ORDER,
    requestTypes.CHECK,
    requestTypes.REFILL,
    requestTypes.CLEAN,
    requestTypes.HELP,
  ],
  HOTEL: [
    requestTypes.ORDER,
    requestTypes.CLEAN,
    requestTypes.ROOM_SERVICE,
    requestTypes.HELP,
  ],
};
