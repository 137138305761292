<template>
  <div>
    <section class="section">
      <div class="container">
        <div class="buttons">
          <b-button
            v-on:click="$router.back()"
            type="is-link"
            icon-left="arrow-left"
            outlined
            >Back</b-button
          >
        </div>
        <h3 class="title is-3">Print the QR code for a spot.</h3>
        <div class="columns mt-2">
          <spot-qr-code v-if="spot" :spot="spot" class="column is-half" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import SpotQrCode from "../components/SpotQrCode.vue";
import { mapState } from "vuex";

export default {
  name: "SingleSpotQrCode",
  components: { SpotQrCode },
  mounted() {
    this.$store.dispatch("FETCH_SPOT", {
      serviceId: this.$route.params.serviceId,
      spotId: this.$route.params.spotId,
    });
  },
  computed: {
    ...mapState({
      spot: (state) => state.spot,
    }),
  },
};
</script>
