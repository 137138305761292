<template>
  <section class="section">
    <div class="container">
      <b-loading :is-full-page="true" v-model="inProgress" />
      <b-button
        v-on:click="$router.back()"
        type="is-link"
        icon-left="arrow-left"
        outlined
        >Back</b-button
      >
      <h3 class="title is-3 mt-6">Settings</h3>

      <h5 class="title is-5 mt-6">Notifications</h5>
      <p class="subtitle is-6">
        Notifications can be enabled so that you can easily know when requests
        are created.
      </p>

      <b-button
        type="is-primary"
        icon-left="bell"
        v-on:click="enableNotifications"
        :loading="enableNotificationsInProgress"
      >
        {{ serviceEnableNotifications ? "Disable" : "Enable" }}
        Notifications</b-button
      >

      <h5 class="title is-5 mt-6">Delete Account</h5>
      <p class="subtitle is-6">
        Delete your account, this cannot be undone and your service page will no
        longer exist.
      </p>
      <b-button type="is-danger" v-on:click="deleteAccount"
        >Delete Account</b-button
      >
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
// @ is an alias to /src

export default {
  name: "Settings",
  data() {
    return {
      inProgress: false,
      enableNotificationsInProgress: false,
    };
  },
  async mounted() {
    await this.$store.dispatch(
      "FETCH_SERVICE_ENABLE_NOTIFICATIONS",
      this.$route.params.serviceId
    );
    this.inProgress = false;
  },
  computed: {
    ...mapState({
      serviceEnableNotifications: (state) => state.serviceEnableNotifications,
    }),
  },
  methods: {
    async deleteAccount() {
      this.$buefy.dialog.confirm({
        message: "Are you sure you want to delete your account?",
        onConfirm: async () => {
          this.inProgress = true;
          try {
            await this.$store.dispatch("DELETE_ACCOUNT");
            this.$router.push({
              name: "Home",
            });
          } catch (e) {
            this.$buefy.toast.open({
              duration: 3000,
              message: `Something went wrong, please try again.`,
              position: "is-bottom",
              type: "is-danger",
            });
          } finally {
            this.inProgress = false;
          }
        },
      });
    },
    async enableNotifications() {
      this.enableNotificationsInProgress = true;
      try {
        await this.$store.dispatch("ENABLE_NOTIFICATIONS", {
          serviceId: this.$route.params.serviceId,
          enable: !this.serviceEnableNotifications,
        });
      } catch (e) {
        this.$buefy.toast.open({
          duration: 3000,
          message: `Something went wrong, please try again.`,
          position: "is-bottom",
          type: "is-danger",
        });
      } finally {
        this.enableNotificationsInProgress = false;
      }
    },
  },
};
</script>
