<template>
  <div>
    <b-loading :is-full-page="true" v-model="submitInProgress" />
    <section class="section">
      <div class="container">
        <h3 class="title is-3">Create Service</h3>
        <h5 class="subtitle is-5">
          Build an access point for your guests to request service.
        </h5>
        <div v-if="user">
          <b-field label="Type" required>
            <b-select
              placeholder="Service Type"
              size="is-medium"
              v-model="type"
            >
              <option value="RESTAURANT">Restaurant</option>
              <option value="HOTEL">Hotel</option>
            </b-select>
          </b-field>

          <b-field label="Service Name">
            <b-input
              maxlength="100"
              size="is-medium"
              v-model="name"
              required
            ></b-input>
          </b-field>

          <b-field label="Description">
            <b-input
              size="is-medium"
              type="textarea"
              minlength="1"
              maxlength="200"
              v-model="description"
              required
            ></b-input>
          </b-field>

          <div class="buttons">
            <b-button
              class="button is-primary"
              size="is-medium"
              v-on:click="createService"
              :loading="submitInProgress"
              :disabled="!(name && type && description)"
              >Submit</b-button
            >
            <b-button
              class="button is-danger"
              size="is-medium"
              v-on:click="$router.back()"
              >Cancel</b-button
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";

export default {
  name: "CreateService",
  components: {},
  data: () => {
    return {
      name: "",
      type: "",
      description: "",
      submitInProgress: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    async createService() {
      this.submitInProgress = true;
      try {
        const serviceId = await this.$store.dispatch("CREATE_SERVICE", {
          name: this.name,
          type: this.type,
          description: this.description,
        });
        this.$router.push({
          name: "ManageService",
          params: { serviceId: serviceId },
        });
      } catch (e) {
        this.$buefy.toast.open({
          duration: 3000,
          message: `Something went wrong, please try again.`,
          position: "is-bottom",
          type: "is-danger",
        });
      } finally {
        this.submitInProgress = false;
      }
    },
  },
};
</script>
