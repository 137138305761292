<template>
  <div class="box">
    <article class="media">
      <div class="media-left">
        <b-icon icon="close" size="is-medium" @click.native="remove" />
      </div>
      <div class="media-content">
        <h6 class="title is-6">Name</h6>
        <h6 class="subtitle is-6">{{ spot.name }}</h6>
        <b-button v-on:click="qrCode" type="is-info">QR Code</b-button>
      </div>
    </article>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "ManageSpot",
  props: {
    spot: Object,
  },
  methods: {
    remove() {
      this.$buefy.dialog.confirm({
        message: `${this.spot.name} will be removed.`,
        onConfirm: () => {
          this.$store.dispatch("DELETE_SPOT", {
            serviceId: this.spot.serviceId,
            spotId: this.spot.id,
          });
        },
      });
    },
    qrCode() {
      this.$router.push({
        name: "SpotQrCode",
        path: "/services/:serviceId/spots/:spotId/code",
        params: {
          serviceId: this.spot.serviceId,
          spotId: this.spot.id,
        },
      });
    },
  },
};
</script>
