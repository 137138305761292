<template>
  <div>
    <section class="section">
      <div class="container">
        <div id="firebaseui-auth-container"></div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import firebase from "firebase/app";
import "firebase/auth";

import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

export default {
  name: "SignIn",
  mounted() {
    var uiConfig = {
      signInSuccessUrl: "/",
      signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
    };
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());
    ui.start("#firebaseui-auth-container", uiConfig);
  },
};
</script>
