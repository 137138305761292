<template>
  <div class="box">
    <h5 class="title is-5">Spots</h5>
    <p>
      A spot is an area where you can provide service. When you create a spot
      you can provide a QR code for your guests. This QR code allows guests to
      visit a page where there will be buttons to request service. Print these
      QR codes out and place them in areas that will make it easy for your staff
      to know where service is need. For example, a table or section is a good
      spot to display a QR code. You can create up to 30 spots.
    </p>
    <br />
    <h5 class="title is-5">Manage Requests</h5>
    <p>
      All requests that guests submit will show up on the dashboard. You should
      be able to see the spot they are making the request from to know where to
      go. We recommend keeping your requests statuses updated to give your
      guests more transparency. If you're working on a request click "In
      Progress" and your guests will be able to see it. If you have completed a
      task, click "Complete" and this will remove the task from the dashboard
      and the guest's service page. We encourage you to actively manage requests
      to avoid confusion.
    </p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HelpModal",
};
</script>
