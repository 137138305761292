<template>
  <div>
    <section class="section">
      <b-loading :is-full-page="true" v-model="submitInProgress" />
      <div class="container">
        <h3 class="title is-3" v-if="spot && spot.serviceName">
          {{ spot.serviceName }}
        </h3>

        <h3 class="subtitle is-5" v-if="spot && spot.name">
          {{ spot.name }}
        </h3>

        <br />
        <h5 class="title is-5" v-if="spotRequests">Current Requests</h5>
        <div class="columns is-multiline" v-if="spotRequests.length > 0">
          <div
            v-for="request in spotRequests"
            v-bind:key="request.id"
            class="column is-one-third"
          >
            <spot-request :request="request" />
          </div>
        </div>
        <h6 class="subtitle is-6" v-else>No current requests</h6>

        <h5 class="title is-5 mt-6">Request Service</h5>
        <h5 class="subtitle is-5">Use the buttons to request service!</h5>

        <div class="mt-3 columns is-multiline is-mobile">
          <div
            v-for="requestType in requestTypes"
            v-bind:key="requestType.type"
            class="column is-one-third-desktop is-half-mobile"
          >
            <div
              class="box has-text-centered"
              v-on:click="openCreateRequestForm(requestType.type)"
            >
              <b-icon :icon="requestType.icon" size="is-medium"> </b-icon>
              <h6 class="title is-6">{{ requestType.displayName }}</h6>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import SpotRequest from "../components/SpotRequest.vue";
import { requestTypesLists } from "../common/request-types.js";

export default {
  name: "SpotService",
  components: { SpotRequest },
  data() {
    return {
      submitInProgress: false,
    };
  },
  mounted() {
    this.$store.dispatch("FETCH_SPOT", {
      serviceId: this.$route.params.serviceId,
      spotId: this.$route.params.spotId,
    });
    this.$store.dispatch("SUBSCRIBE_SPOT_REQUESTS", {
      serviceId: this.$route.params.serviceId,
      spotId: this.$route.params.spotId,
    });
  },
  beforeDestroy() {
    this.$store.dispatch("UNSUBSCRIBE_SPOT_REQUESTS");
  },
  methods: {
    openCreateRequestForm(type) {
      if (this.spotRequests.length >= 5) {
        this.$buefy.toast.open({
          duration: 3000,
          message: `Only 5 requests are allowed to be open per spot.`,
          position: "is-bottom",
          type: "is-danger",
        });
        return;
      }

      this.$buefy.dialog.confirm({
        message: "Send request?",
        onConfirm: async () => {
          this.submitInProgress = true;
          try {
            await this.$store.dispatch("ADD_REQUEST", {
              serviceId: this.$route.params.serviceId,
              spotId: this.$route.params.spotId,
              type: type,
            });
          } catch (e) {
            this.$buefy.toast.open({
              duration: 3000,
              message: `Something went wrong, please try again.`,
              position: "is-bottom",
              type: "is-danger",
            });
          } finally {
            this.submitInProgress = false;
          }
        },
      });
    },
  },
  computed: {
    ...mapState({
      spot: (state) => state.spot,
      requestTypes: (state) =>
        state.spot ? requestTypesLists[state.spot.serviceType] : [],
      spotRequests: (state) => (state.spotRequests ? state.spotRequests : []),
    }),
  },
  watch: {
    "$store.state.spotRequests": function () {
      this.$buefy.toast.open({
        duration: 1500,
        message: `Requests Updated`,
        position: "is-bottom",
        type: "is-info",
      });
    },
  },
};
</script>
