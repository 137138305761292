<template>
  <div>
    <b-loading :is-full-page="true" v-model="loading" />
    <section class="section" v-if="spots">
      <div class="container">
        <b-button
          v-on:click="$router.back()"
          type="is-link"
          icon-left="arrow-left"
          outlined
          >Back</b-button
        >
        <h2 class="mt-6 title is-2">Spots</h2>
        <div class="columns is-multiline mt-6" v-if="spots.length > 0">
          <div
            v-for="spot in spots"
            v-bind:key="spot.id"
            class="column is-half is-gapless"
          >
            <spot-qr-code :spot="spot" />
          </div>
        </div>
        <p class="mt-6" v-else>Your service has no spots to print.</p>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import SpotQrCode from "../components/SpotQrCode.vue";

export default {
  name: "PrintSpots",
  components: {
    SpotQrCode,
  },
  data() {
    return {
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    try {
      this.$store.dispatch("FETCH_SPOTS", this.$route.params.serviceId);
    } catch (e) {
      this.$buefy.toast.open({
        duration: 3000,
        message: `Something went wrong, please try again.`,
        position: "is-bottom",
        type: "is-danger",
      });
    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapState({
      spots: (state) => state.spots,
    }),
  },
  methods: {
    getQrCode(spot) {
      const params = this.$route.params;
      return `${window.location.host}/#/services/${params.serviceId}/spots/${spot.id}`;
    },
  },
};
</script>
