<template>
  <div class="card">
    <div class="card-content">
      <article class="media">
        <figure class="media-left" v-if="request">
          <b-icon :icon="request.icon" size="is-medium"> </b-icon>
        </figure>
        <div class="media-content request-card-content">
          <div class="content">
            <h6 class="title is-6">Name</h6>
            <p class="subtitle is-6">{{ request.spotName }}</p>
            <h6 class="mt-1 title is-6">Type</h6>
            <p class="subtitle is-6">{{ request.typeName }}</p>
            <h6 class="mt-1 title is-6">Created At</h6>
            <p class="subtitle is-6">
              {{ request.createdAt.toDate().toLocaleString() }}
            </p>
          </div>
        </div>
        <div class="media-right">
          <b-tag type="is-info">
            {{ request.statusName }}
          </b-tag>
        </div>
      </article>
    </div>
    <footer class="card-footer">
      <a class="card-footer-item" v-on:click="inProgress">In Progress</a>
      <a class="card-footer-item" v-on:click="complete">Complete</a>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "ManageSpotRequest",
  props: {
    request: Object,
  },
  methods: {
    async complete() {
      this.$buefy.dialog.confirm({
        message: "Complete request?",
        onConfirm: () => {
          this.$store.dispatch("REQUEST_DONE", {
            serviceId: this.request.serviceId,
            requestId: this.request.id,
          });
        },
      });
    },
    async inProgress() {
      this.$buefy.dialog.confirm({
        message: "Is request in progress?",
        onConfirm: () => {
          this.$store.dispatch("REQUEST_IN_PROGRESS", {
            serviceId: this.request.serviceId,
            requestId: this.request.id,
          });
        },
      });
    },
  },
};
</script>

<style lang="scss">
.request-card-content {
  overflow: hidden;
}
</style>
