<template>
  <div class="has-text-centered print-spot">
    <h5 class="title is-5 mt-6">{{ spot.name }}</h5>
    <p class="subtitle is-5">Scan QR code to request service</p>

    <vue-qrcode
      class="mt-5 mb-5"
      :value="getQrCodeUrl"
      :options="{ width: 200, margin: 0 }"
    ></vue-qrcode>
  </div>
</template>

<script>
// @ is an alias to /src
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default {
  name: "SpotQrCode",
  components: { VueQrcode },
  props: {
    spot: Object,
  },
  data: () => {
    return {};
  },
  computed: {
    getQrCodeUrl() {
      return `${window.location.host}/#/services/${this.spot.serviceId}/spots/${this.spot.id}`;
    },
  },
};
</script>

<style lang="scss">
.print-spot {
  border-style: dashed;
}
</style>
