<template>
  <div>
    <qrcode-stream @decode="onDecode"></qrcode-stream>
  </div>
</template>

<script>
// @ is an alias to /src
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name: "QRCodeScanner",
  components: {
    QrcodeStream,
  },
  methods: {
    onDecode(decoded) {
      window.location.replace(decoded);
    },
  },
};
</script>
