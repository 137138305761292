<template>
  <div>
    <b-loading :is-full-page="true" v-model="submitInProgress" />
    <section class="section">
      <div class="container">
        <h3 class="title is-3">Create Spot</h3>
        <h5 class="subtitle is-5">
          Create a spot for guests to check in and request service.
        </h5>

        <b-field label="Name">
          <b-input maxlength="50" size="is-medium" v-model="name"></b-input>
        </b-field>

        <div class="buttons">
          <b-button
            class="button is-primary"
            size="is-medium"
            v-on:click="createSpot"
            :loading="submitInProgress"
            :disabled="!name"
            >Submit</b-button
          >
          <b-button
            class="button is-danger"
            size="is-medium"
            v-on:click="$router.back()"
            >Cancel</b-button
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "CreateSpot",
  components: {},
  data() {
    return {
      submitInProgress: false,
      name: "",
    };
  },
  methods: {
    async createSpot() {
      this.submitInProgress = true;
      try {
        const spot = await this.$store.dispatch("CREATE_SPOT", {
          name: this.name,
          serviceId: this.$route.params.serviceId,
        });
        this.$router.replace({
          name: "SpotQrCode",
          path: "/services/:serviceId/spots/:spotId/code",
          params: {
            serviceId: this.$route.params.serviceId,
            spotId: spot.id,
          },
        });
      } catch (e) {
        this.$buefy.toast.open({
          duration: 3000,
          message: `Something went wrong, please try again.`,
          position: "is-bottom",
          type: "is-danger",
        });
      } finally {
        this.submitInProgress = false;
      }
    },
  },
};
</script>
