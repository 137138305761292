import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/messaging";
import { requestTypes } from "../common/request-types";
import { requestStatuses } from "../common/request-statuses";

const USERS_V1 = "usersV1";
const USER_MANAGE_SERVICE_V1 = "userManageServiceV1";
const SERVICES_V1 = "servicesV1";
const SPOTS_V1 = "spotsV1";
const REQUESTS_V1 = "requestsV1";
const NOTIFICATION_TOKENS_V1 = "notificationTokensV1";

const firebaseConfig = {
  apiKey: "AIzaSyAmV0jb33itulMeulxM2CteVFpbqJuifEA",
  authDomain: "chime-pad-ae768.firebaseapp.com",
  projectId: "chime-pad-ae768",
  storageBucket: "chime-pad-ae768.appspot.com",
  messagingSenderId: "608378294083",
  appId: "1:608378294083:web:2db5be5d1227495051a9f6",
  measurementId: "G-H4MBYFWP2H",
};
const vapidKey =
  "BB5R1yze_viIv2OViR_V056kT4zayGZPawI_nLzF7w9cCSz2x6dCmzGYKNGHlK75CEmK8DSaYX9whMguQJ1Brts";

// Initialize Firebase
const firestore = firebase.initializeApp(firebaseConfig).firestore();
const auth = firebase.auth();
const functions = firebase.functions();
if (process.env.NODE_ENV == "development") {
  firestore.useEmulator("localhost", 8090);
  auth.useEmulator("http://localhost:9099");
  functions.useEmulator("localhost", "5001");
}

const messaging = firebase.messaging();

const servicesCollection = firestore.collection(SERVICES_V1);
var manageRequestsUnsubscribe;
var spotRequestsUnsubscribe;

function getInitialState() {
  return {
    user: null,
    manageServiceId: null,
    serviceEnableNotifications: false,
    service: null,
    spot: null,
    spots: [],
    requests: [],
    spotRequests: [],
  };
}

Vue.use(Vuex);

const store = new Vuex.Store({
  state: getInitialState(),
  mutations: {
    SET_USER(state, value) {
      state.user = value;
    },
    SET_USER_SERVICE(state, value) {
      state.manageServiceId = value;
    },
    SET_SERVICE(state, value) {
      state.service = value;
    },
    SET_REQUESTS(state, value) {
      state.requests = value;
    },
    SET_SPOTS(state, value) {
      state.spots = value;
    },
    SET_SPOT(state, value) {
      state.spot = value;
    },
    SET_SPOT_REQUESTS(state, value) {
      state.spotRequests = value;
    },
    SET_ENABLE_NOTIFICATIONS(state, value) {
      state.serviceEnableNotifications = value;
    },
    RESET_STATE(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async SUBSCRIBE_AUTH_CHANGE(context) {
      firebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
          context.dispatch("FETCH_USER", firebaseUser);
        } else {
          context.commit("SET_USER", null);
        }
      });
    },

    async FETCH_USER(context, firebaseUser) {
      const userDoc = await firestore
        .collection(USERS_V1)
        .doc(firebaseUser.uid)
        .get();
      const userData = userDoc.data();

      const user = {
        id: firebaseUser.uid,
        displayName: firebaseUser.displayName,
        verified: userData ? userData.verified : false,
      };

      if (userData) {
        context.commit("SET_USER_SERVICE", userData[USER_MANAGE_SERVICE_V1]);
      }

      context.commit("SET_USER", user);
    },

    async SIGN_OUT(context) {
      await messaging.deleteToken();
      await firebase.auth().signOut();
      context.commit("RESET_STATE");
    },

    async CREATE_SERVICE(context, { name, type, description }) {
      const user = firebase.auth().currentUser;

      if (!user) {
        return;
      }

      const createService = functions.httpsCallable("createService");
      const serviceResponse = await createService({
        name: name,
        type: type,
        description: description,
      });
      const service = serviceResponse.data;
      context.commit("SET_USER_SERVICE", service.id);

      return service.id;
    },
    async EDIT_SERVICE(context, { serviceId, name, type, description }) {
      const user = firebase.auth().currentUser;

      if (!user) {
        return;
      }

      const editService = functions.httpsCallable("editService");
      const serviceResponse = await editService({
        serviceId: serviceId,
        name: name,
        type: type,
        description: description,
      });
      const service = serviceResponse.data;
      context.commit("SET_USER_SERVICE", service.id);

      return service.id;
    },
    async FETCH_SPOT(context, { serviceId, spotId }) {
      context.commit("SET_SPOT", null);

      const spotDoc = await servicesCollection
        .doc(serviceId)
        .collection(SPOTS_V1)
        .doc(spotId)
        .get();
      const data = spotDoc.data();
      const spot = {
        id: spotDoc.id,
        name: data.name,
        serviceId: serviceId,
        serviceName: data.serviceName,
        serviceType: data.serviceType,
        serviceDescription: data.serviceDescription,
      };

      context.commit("SET_SPOT", spot);
    },
    SUBSCRIBE_SPOT_REQUESTS(context, { serviceId, spotId }) {
      spotRequestsUnsubscribe = servicesCollection
        .doc(serviceId)
        .collection(REQUESTS_V1)
        .where("spotId", "==", spotId)
        .orderBy("createdAt", "asc")
        .onSnapshot((snapshot) => {
          const requests = snapshot.docs.map((doc) => {
            const data = doc.data();

            const request = {
              id: doc.id,
              name: data.spotName,
              type: data.type,
              typeName: requestTypes[data.type].displayName,
              icon: requestTypes[data.type].icon,
              status: data.status,
              statusName: requestStatuses[data.status].displayName,
              createdAt: data.createdAt,
            };
            return request;
          });
          store.commit("SET_SPOT_REQUESTS", requests);
        });
    },
    UNSUBSCRIBE_SPOT_REQUESTS() {
      if (spotRequestsUnsubscribe) {
        spotRequestsUnsubscribe();
      }
    },
    async FETCH_SPOTS(context, serviceId) {
      const spotsDocs = await servicesCollection
        .doc(serviceId)
        .collection(SPOTS_V1)
        .get();
      const spots = spotsDocs.docs.map((doc) => {
        const data = doc.data();
        const spot = {
          id: doc.id,
          name: data.name,
          serviceId: serviceId,
        };
        return spot;
      });
      store.commit("SET_SPOTS", spots);
    },
    async FETCH_SERVICE(context, serviceId) {
      const serviceDoc = await servicesCollection.doc(serviceId).get();
      const data = serviceDoc.data();
      const service = {
        name: data.name,
        type: data.type,
        description: data.description,
      };
      context.commit("SET_SERVICE", service);
      return service;
    },
    async FETCH_SERVICE_ENABLE_NOTIFICATIONS(context, serviceId) {
      if (Notification.permission !== "granted") {
        return null;
      }

      const currentToken = await messaging.getToken(messaging, {
        vapidKey: vapidKey,
      });
      const serviceDoc = await servicesCollection
        .doc(serviceId)
        .collection(NOTIFICATION_TOKENS_V1)
        .doc(currentToken)
        .get();

      var notificationsEnabled = false;

      if (serviceDoc.data() != null) {
        notificationsEnabled = serviceDoc.data().enabled;
      }

      context.commit("SET_ENABLE_NOTIFICATIONS", notificationsEnabled);
    },
    SUBSCRIBE_REQUESTS(context, serviceId) {
      manageRequestsUnsubscribe = servicesCollection
        .doc(serviceId)
        .collection(REQUESTS_V1)
        .orderBy("createdAt", "asc")
        .onSnapshot((snapshot) => {
          const requests = snapshot.docs.map((doc) => {
            const data = doc.data();
            const request = {
              id: doc.id,
              spotName: data.spotName,
              serviceId: serviceId,
              type: data.type,
              typeName: requestTypes[data.type].displayName,
              icon: requestTypes[data.type].icon,
              status: data.status,
              statusName: requestStatuses[data.status].displayName,
              createdAt: data.createdAt,
            };
            return request;
          });
          store.commit("SET_REQUESTS", requests);
        });
    },
    UNSUBSCRIBE_REQUESTS() {
      if (manageRequestsUnsubscribe) {
        manageRequestsUnsubscribe();
      }
    },
    async DELETE_SPOT(context, { serviceId, spotId }) {
      await servicesCollection
        .doc(serviceId)
        .collection(SPOTS_V1)
        .doc(spotId)
        .delete();
    },
    async DELETE_ALL_SPOTS(context, serviceId) {
      const batch = firestore.batch();
      const snapshot = await servicesCollection
        .doc(serviceId)
        .collection(SPOTS_V1)
        .get();
      snapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();
    },
    async ADD_REQUEST(context, { serviceId, spotId, type }) {
      const createRequest = functions.httpsCallable("createRequest");
      await createRequest({
        serviceId: serviceId,
        spotId: spotId,
        type: type,
      });
    },
    async CLEAR_REQUESTS(context, serviceId) {
      const batch = firestore.batch();
      const snapshot = await servicesCollection
        .doc(serviceId)
        .collection(REQUESTS_V1)
        .get();
      snapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();
    },
    async REQUEST_DONE(context, { serviceId, requestId }) {
      await servicesCollection
        .doc(serviceId)
        .collection(REQUESTS_V1)
        .doc(requestId)
        .delete();
    },
    async REQUEST_IN_PROGRESS(context, { serviceId, requestId }) {
      await servicesCollection
        .doc(serviceId)
        .collection(REQUESTS_V1)
        .doc(requestId)
        .update({
          status: "IN_PROGRESS",
        });
    },
    async CREATE_SPOT(context, { serviceId, name }) {
      const createSpot = functions.httpsCallable("createSpot");
      const createSpotResponse = await createSpot({
        name: name,
        serviceId: serviceId,
      });
      return createSpotResponse.data;
    },
    async DELETE_ACCOUNT(context) {
      await messaging.deleteToken();
      await firebase.auth().currentUser.delete();
      context.commit("RESET_STATE");
    },
    async ENABLE_NOTIFICATIONS(context, { enable }) {
      if (Notification.permission !== "granted") {
        Notification.requestPermission();
        return null;
      }

      const currentToken = await messaging.getToken(messaging, {
        vapidKey: vapidKey,
      });

      const enableNotifications = functions.httpsCallable(
        "enableServiceNotifications"
      );
      await enableNotifications({
        serviceId: context.state.manageServiceId,
        token: currentToken,
        enable: enable,
      });
      context.commit("SET_ENABLE_NOTIFICATIONS", enable);
    },
  },
});

export default store;
