<template>
  <div>
    <b-loading :is-full-page="true" v-model="serviceLoading" />
    <section class="section" v-if="service">
      <div class="container">
        <h3 class="title is-3">{{ service.name }}</h3>
        <div class="buttons mb-3">
          <b-button
            type="is-primary"
            icon-left="map-marker-multiple"
            tag="router-link"
            :to="`/services/${$route.params.serviceId}/spots`"
            >Spots</b-button
          >

          <b-button type="is-info" icon-left="help" v-on:click="helpModal"
            >Help</b-button
          >

          <b-button
            icon-left="pencil"
            type="is-info"
            tag="router-link"
            :to="`/services/${$route.params.serviceId}/edit`"
            outlined
            >Edit</b-button
          >

          <b-button
            icon-left="cog"
            type="is-info"
            tag="router-link"
            :to="`/services/${$route.params.serviceId}/settings`"
            outlined
            >Settings</b-button
          >
          <b-button
            class="button is-danger"
            icon-left="close-circle-multiple"
            v-on:click="clearRequests"
            >Clear All Requests</b-button
          >
        </div>

        <h5 class="mt-6 title is-5">Current Requests</h5>
        <h5 class="subtitle is-5">Manage requests made by guests</h5>
        <div v-if="requests.length > 0">
          <div class="columns is-multiline">
            <div
              v-for="request in requests"
              v-bind:key="request.id"
              class="column is-one-third"
            >
              <manage-spot-request :request="request" />
            </div>
          </div>
        </div>
        <p v-else>No requests at the moment...</p>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import ManageSpotRequest from "../components/ManageSpotRequest.vue";
import HelpModal from "../components/HelpModal.vue";
import firebase from "firebase/app";
import "firebase/messaging";
var notificationSubscriber;

export default {
  name: "ManageService",
  components: { ManageSpotRequest },
  data() {
    return {
      serviceLoading: false,
    };
  },
  async mounted() {
    this.serviceLoading = true;
    try {
      await this.$store.dispatch("FETCH_SERVICE", this.$route.params.serviceId);
      this.$store.dispatch("SUBSCRIBE_REQUESTS", this.$route.params.serviceId);
      notificationSubscriber = firebase.messaging().onMessage(() => {
        console.log("NOTIFICATION");
        this.$buefy.toast.open({
          duration: 1500,
          message: `Requests Updated`,
          position: "is-bottom",
          type: "is-info",
        });
      });
    } catch (e) {
      this.$buefy.toast.open({
        duration: 3000,
        message: `Something went wrong, please try again.`,
        position: "is-bottom",
        type: "is-danger",
      });
    } finally {
      this.serviceLoading = false;
    }
  },
  beforeDestroy() {
    notificationSubscriber();
    this.$store.dispatch("UNSUBSCRIBE_REQUESTS");
  },
  computed: {
    ...mapState({
      service: (state) => state.service,
      requests: (state) =>
        state.requests.filter((request) => {
          return request.statusType != "DONE";
        }),
    }),
  },
  methods: {
    async clearRequests() {
      this.$buefy.dialog.confirm({
        message: "Clear all requests?",
        onConfirm: () => {
          this.$store.dispatch("CLEAR_REQUESTS", this.$route.params.serviceId);
        },
      });
    },
    helpModal() {
      this.$buefy.modal.open({
        parent: this,
        component: HelpModal,
        hasModalCard: false,
        trapFocus: true,
      });
    },
  },
};
</script>
