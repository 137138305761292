import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import ManageService from "../views/ManageService.vue";
import Spots from "../views/Spots.vue";
import SingleSpotQrCode from "../views/SingleSpotQrCode.vue";
import SpotService from "../views/SpotService.vue";
import CreateService from "../views/CreateService.vue";
import EditService from "../views/EditService.vue";
import CreateSpot from "../views/CreateSpot.vue";
import PrintSpots from "../views/PrintSpots.vue";
import SignIn from "../views/SignIn.vue";
import QRCodeScanner from "../views/QRCodeScanner.vue";
import Settings from "../views/Settings.vue";
// import store from "../store/index";
import firebase from "firebase/app";
import "firebase/auth";

Vue.use(VueRouter);

const fetchCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

const requireAuth = async (to, from, next) => {
  const currentUser = await fetchCurrentUser();
  if (currentUser) {
    next();
  } else {
    next({ name: "SignIn" });
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/auth/sign-in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/services/:serviceId/manage",
    name: "ManageService",
    component: ManageService,
    beforeEnter: requireAuth,
  },
  {
    path: "/services/create",
    name: "CreateService",
    component: CreateService,
    beforeEnter: requireAuth,
  },
  {
    path: "/services/:serviceId/edit",
    name: "EditService",
    component: EditService,
    beforeEnter: requireAuth,
  },
  {
    path: "/services/:serviceId/spots",
    name: "Spots",
    component: Spots,
    beforeEnter: requireAuth,
  },
  {
    path: "/services/:serviceId/spots/print",
    name: "PrintSpots",
    component: PrintSpots,
    beforeEnter: requireAuth,
  },
  {
    path: "/services/:serviceId/spots/create",
    name: "CreateSpot",
    component: CreateSpot,
    beforeEnter: requireAuth,
  },
  {
    path: "/services/:serviceId/spots/:spotId/code",
    name: "SpotQrCode",
    component: SingleSpotQrCode,
    beforeEnter: requireAuth,
  },
  {
    path: "/services/:serviceId/spots/:spotId",
    name: "SpotService",
    component: SpotService,
  },
  {
    path: "/services/check-in/scan",
    name: "QRCodeScanner",
    component: QRCodeScanner,
  },
  {
    path: "/services/:serviceId/settings",
    name: "Settings",
    component: Settings,
    beforeEnter: requireAuth,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
