<template>
  <div class="card">
    <div class="card-content">
      <article class="media">
        <figure class="media-left" v-if="request">
          <b-icon :icon="request.icon" size="is-medium"> </b-icon>
        </figure>
        <div class="media-content spot-card-content">
          <h6 class="title is-6">Request</h6>
          <p class="subtitle is-6">{{ request.typeName }}</p>
          <h6 class="title is-6">Created</h6>
          <p class="subtitle is-6">
            {{ request.createdAt.toDate().toLocaleString() }}
          </p>
        </div>
        <div class="media-right">
          <b-tag type="is-info">
            {{ request.statusName }}
          </b-tag>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "SpotRequest",
  props: {
    request: Object,
  },
};
</script>

<style lang="scss">
.spot-card-content {
  overflow: hidden;
}
</style>
