<template>
  <div class="about">
    <section class="hero is-medium">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-one-half">
              <h2 class="title is-2">About</h2>
              <p>
                Our mission is to provide a simple service point that your
                customers can use to receive responsive services. The
                performance of a service can affect your business's ratings,
                returning customers, as well as customer throughput. We want a
                smooth and transparent service experience for both customers and
                businesses. Customers will be able to stay connected during
                their visit while your staff will be able to stay organized.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "About",
};
</script>
