<template>
  <div id="app">
    <b-navbar class="p-3">
      <template #brand>
        <b-navbar-item tag="router-link" to="/">
          <img width="50" height="50" src="./assets/graphics/logo.svg" />
          <h6 class="title is-6">Chime Pad</h6>
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item tag="router-link" to="/">Home</b-navbar-item>
        <b-navbar-item tag="router-link" to="/about">About</b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="`/services/${manageServiceId}/manage`"
          v-if="user && manageServiceId"
          >Manage Service</b-navbar-item
        >
        <b-navbar-item
          tag="router-link"
          to="/services/create"
          v-if="user && !manageServiceId && user.verified"
          >Create Service</b-navbar-item
        >
      </template>
      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <b-button
              v-if="!user"
              type="is-link"
              tag="router-link"
              to="/auth/sign-in"
              >Sign In</b-button
            >
            <b-button v-if="user" type="is-link" v-on:click="signOut"
              >Sign out</b-button
            >
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";

export default {
  name: "App",
  mounted() {
    this.$store.dispatch("SUBSCRIBE_AUTH_CHANGE");
  },
  methods: {
    signOut() {
      this.$store.dispatch("SIGN_OUT");
      this.$router.replace("/");
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      manageServiceId: (state) => state.manageServiceId,
    }),
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@500&family=Cabin:wght@500&family=Karla&family=Noto+Sans&family=Nunito&family=Open+Sans:wght@500&family=Source+Sans+Pro&display=swap");
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // font-family: "Arimo", sans-serif;
  // font-family: "Cabin", sans-serif;
  // font-family: "Karla", sans-serif;
  font-family: "Noto Sans", sans-serif;
  // font-family: "Nunito", sans-serif;
  // font-family: "Open Sans", sans-serif;
  // font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
