export const requestStatuses = {
  TODO: {
    type: "TODO",
    displayName: "Todo",
  },
  DONE: {
    type: "DONE",
    displayName: "Done",
  },
  IN_PROGRESS: {
    type: "IN_PROGRESS",
    displayName: "In Progress",
  },
};
