<template>
  <div class="home">
    <section class="hero is-medium">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-vcentered">
            <div class="column is-half">
              <h2 class="title is-2">Service Made Easy</h2>
              <h4 class="subtitle is-5">
                A simple way to request services through a QR code.
              </h4>
              <b-button
                class="is-hidden-desktop"
                type="is-primary"
                size="is-medium"
                icon-left="qrcode"
                tag="router-link"
                to="/services/check-in/scan"
              >
                Scan QR Code
              </b-button>
            </div>
            <div class="column is-one-sixth">
              <img
                class="box landing-screen-image"
                src="../assets/graphics/customer_ux.png"
              />
            </div>
            <div class="column is-one-sixth">
              <img
                class="box landing-screen-image"
                src="../assets/graphics/manager_ux.png"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-medium">
      <div class="hero-body">
        <div class="has-text-centered">
          <h3 class="title is-3">Manage Service Requests</h3>
          <h5 class="subtitle is-5">
            We provide a simple tool to manage requests made by customers.
          </h5>
        </div>
        <div class="mt-6 columns is-centered">
          <div class="column is-one-third has-text-centered">
            <b-icon icon="clock-fast" size="is-large" />
            <h6 class="mt-2 title is-6">Efficient Service</h6>
            <p class="has-text-left">
              Viewing service requests gives you the ability to organize and
              quickly help your customers which reduces overall wait time.
            </p>
          </div>
          <div class="column is-one-third has-text-centered">
            <b-icon icon="star" size="is-large" />
            <h6 class="mt-2 title is-6">Better Reviews</h6>
            <p class="has-text-left">
              Improve business reviews with a better experience for customers.
              This brings in new customers that will visit again!
            </p>
          </div>
          <div class="column is-one-third has-text-centered">
            <b-icon icon="wrench" size="is-large" />
            <h6 class="mt-2 title is-6">Easy to setup</h6>
            <p class="has-text-left">
              All you need is to create spots and print out the QR codes! We
              provide predefined request buttons for your service.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
};
</script>

<style lang="scss">
.landing-screen-image {
  border-style: solid;
  border-color: lightgrey;
  border-width: 1px;
  border-radius: 5px;
}
</style>
